<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item to="/limit_seckill">限时秒杀</el-breadcrumb-item>
            <el-breadcrumb-item>{{ action === 'add' ? '新增' : '编辑' }}秒杀预售券</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 返回页头 -->
      <el-page-header @back="goBack" :content="`${ action === 'add' ? '新增' : '编辑' }秒杀预售券`"></el-page-header>
      <el-row class="seckill-form">
         <el-row class="seckill-radio">
            <label class="width-90 left-label"><b class="needful">*</b>秒杀日期：</label>
            <el-row class="right-radio">
               <el-row><el-radio v-model="dateType" label="EVERYDAY">每天</el-radio></el-row>
               <el-row class="radio-flex">
                  <el-radio v-model="dateType" label="WEEK">指定日期</el-radio>
                  <el-checkbox-group v-model="checkWeek" :disabled="dateType !== 'WEEK'">
                     <el-checkbox label="mon">星期一</el-checkbox>
                     <el-checkbox label="tue">星期二</el-checkbox>
                     <el-checkbox label="wed">星期三</el-checkbox>
                     <el-checkbox label="thu">星期四</el-checkbox>
                     <el-checkbox label="fri">星期五</el-checkbox>
                     <el-checkbox label="sat">星期六</el-checkbox>
                     <el-checkbox label="sun">星期日</el-checkbox>
                  </el-checkbox-group>
               </el-row>
               <el-row class="radio-flex">
                  <el-radio v-model="dateType" label="DATE">指定日期范围</el-radio>
                  <el-date-picker
                      v-model="dateRange"
                      :disabled="dateType !== 'DATE'"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
               </el-row>
            </el-row>
         </el-row>
         <el-row>
            <label class="width-90 left-label"><b class="needful">*</b>秒杀时间段：</label>
            <el-select v-model="seckillTimeId"  placeholder="请选择">
               <el-option
                   v-for="item in seckillTime"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
            </el-select>
         </el-row>
         <el-row>
            <label class="width-90 left-label"><b class="needful">*</b>秒杀预售券：</label>
            <el-button  class="bg-gradient"  type="primary" @click="visible = true">选择秒杀预售券</el-button>
         </el-row>
         <el-row>
            <el-table
                ref="mainTable"
                border
                :data="selectCoupons"
                :stripe="true"
                >
               <el-table-column prop="mainTitle" label="预售券名称"></el-table-column>
               <el-table-column prop="mainTitle" label="预售券产品名称"></el-table-column>
               <el-table-column prop="issueCount" label="预售券产品数量"></el-table-column>
               <el-table-column prop="presellPrice" label="预售券价格"></el-table-column>
               <el-table-column label="秒杀价格">
                  <template slot-scope="scope">
                     <el-input-number  v-model="scope.row.seckillPrice" :min="0.1" label="描述文字"></el-input-number>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
         <el-row>
            <el-button class="bg-gradient" type="primary" @click="saveSeckillProduct">保存</el-button>
         </el-row>
      </el-row>
      <!-- 选择商品 -->
      <el-dialog title="选择预售券" :visible.sync="visible" width="900px">
         <el-row class="search-box">
            <el-row>
               <label>预售券名称：</label>
               <el-input class="width-175 m-left-5"  v-model="mainTitle"></el-input>
            </el-row>
<!--            <el-row>
               <label>秒杀时段：</label>
               <el-select v-model="typeQ"  class="width-175" :placeholder="$t('msg.select')">
                  <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
               </el-select>
            </el-row>-->
            <el-row>
               <el-button class="bg-gradient" type="primary" @click="searchCoupon(true)">搜索</el-button>
               <!-- <el-button  @click="searchCoupon(false)">重置</el-button>-->
            </el-row>
         </el-row>
         <el-table
             ref="mainTable"
             border
             :data="couponData"
             highlight-current-row
             @current-change="currentChange"
             :stripe="true"
             >
            <el-table-column prop="mainTitle" label="预售券名称" min-width="150"></el-table-column>
            <el-table-column prop="presellPrice" label="价格" min-width="140"></el-table-column>
            <el-table-column prop="issueCount" label="库存"></el-table-column>
         </el-table>
         <span slot="footer" class="dialog-footer">
            <el-button  @click="visible = false">取消</el-button>
            <el-button class="bg-gradient" type="primary" @click="saveSelectCoupon()" v-text="$t('msg.save')">保存</el-button>
         </span>
      </el-dialog>
   </section>
</template>
<script>
import {urlObj} from '@/api/interface'
import { operate } from  '@/api/interface/smtech'
import {preSale} from "@/api/interface/business";
   import { dateFactory } from "@/common/js/common";
   import { mapState } from 'vuex'
   export default {
      data(){
         return{
            visible: false,        // 选择商品
            hotelId: '',           // 酒店ID
            dateType: 'EVERYDAY',  // 日期类型
            couponData: [],        // 预售券列表
            currentCoupon: [],     // 当前预售券
            selectCoupons: [],     // 选中预售券列表
            seckillTime: [],       // 秒杀时间段列表
            seckillTimeId: '',     // 秒杀时间段ID
            productId: '',         // 预售券产品ID
            dateRange: [],         // 日期范围
            mainTitle: '',         // 预售券名称
            action: 'add',         // 操作行为
            id: '',                // 编辑ID
            limit: 1,
            page: 1,
            total: 1,
            checkWeek: [
               'mon', 'tue', 'wed', 'thu',
               'fri', 'sat', 'sun'
            ],         // 选中星期
            edit_success: "修改成功！",
            add_success: "添加成功！",
            seckillTimeId_no_null: '秒杀时间段不能为空！',
            productId_no_null: '秒杀预售券不能为空！',
            seckillPrice_no_null: '秒杀价格不能为空!',
            dateRange_no_null: '日期范围不能为空！',
            checkWeek_no_null: '指定日期不能为空！'
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelId = this.hotelInfo.id
         this.getSeckillTime()
         this.getPreSalePage()
         this.action = this.$route.query.action
         this.action === 'edit' && this.getSeckillProduct()
      },
      methods: {
         // 获取预售券编辑信息
         getSeckillProduct(){
            let seckillProductObj = JSON.parse(sessionStorage.getItem('seckillProductObj'))
            this.id = seckillProductObj.id
            this.dateType = seckillProductObj.dateType
            if (this.dateType === 'WEEK') {
               seckillProductObj.mon === 'YES' && this.checkWeek.push('mon')
               seckillProductObj.tue === 'YES' && this.checkWeek.push('tue')
               seckillProductObj.wed === 'YES' && this.checkWeek.push('wed')
               seckillProductObj.thu === 'YES' && this.checkWeek.push('thu')
               seckillProductObj.fri === 'YES' && this.checkWeek.push('fri')
               seckillProductObj.sat === 'YES' && this.checkWeek.push('sat')
               seckillProductObj.sun === 'YES' && this.checkWeek.push('sun')
            }
            this.dateType === 'DATE' && this.dateRange.push(this.startDate, this.endDate)
            this.seckillTimeId = seckillProductObj.seckillTimeId
            this.productId = seckillProductObj.productId
            this.getPreSalePage(() => {
               this.couponData.forEach(item => {
                  item.id === this.productId && this.selectCoupons.push(item.seckillPrice = this.seckillPrice)
               })
            })
            this.mainTitle = seckillProductObj.mainTitle
            this.seckillPrice = seckillProductObj.seckillPrice
         },
         // 预售卷列表
         getPreSalePage(callback){
            const url = preSale.preSalePage
            let param = {
               page: this.page,
               limit: this.limit,
               hotelId: this.hotelId,
              showGift: false,
               status: 'PROCESSING',
            }
            this.$axios.post(url,param).then(res => {
               if (res.success) {
                  this.total = res.total
                  this.couponData = res.records
                  this.couponData.forEach(item => item.presellPrice = item.presellPrice / 100)
                  callback && callback()
               }
            })
         },
         // 选中当前预售券
         currentChange(row){
            this.currentCoupon = row
         },
         // 保存当前预售券
         saveSelectCoupon(){
            this.visible = false
            this.productId = this.currentCoupon.id
            this.mainTitle = this.currentCoupon.mainTitle
            this.selectCoupons = []
            this.selectCoupons.push(this.currentCoupon)
         },
         // 获取秒杀时间段列表
         getSeckillTime(){
            const url = operate.seckillTime
            const param = { hotelId: this.hotelId }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let seckillTime = res.records
                  this.seckillTime = seckillTime.map(item => {
                     return { value: item.id, label: item.startTime + '~' + item.endTime }
                  })
               }
            })
         },
         // 保存秒杀商品
         saveSeckillProduct(){
            // 表单验证
            if (!this.seckillTimeId) {
               this.$alert(this.seckillTimeId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.productId) {
               this.$alert(this.productId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.dateType === 'DATE') {
               if (this.dateRange.length === 0) {
                  this.$alert(this.dateRange_no_null, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
                  return
               }
            }
            if (this.dateType === 'WEEK') {
               if (this.checkWeek.length === 0) {
                  this.$alert(this.checkWeek_no_null, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
                  return
               }
            }
            let seckillPrice = this.selectCoupons[0].seckillPrice
            if (!seckillPrice) {
               this.$alert(this.seckillPrice_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = operate.addSeckillProduct
            let param = {
               hotelId: this.hotelId,
               dateType: this.dateType,
               seckillTimeId: this.seckillTimeId,
               productId: this.productId,
               mainTitle: this.mainTitle,
               seckillPrice,
               status: 'ONSHELF',
               type: 'TICKET'
            }
            if (this.dateType === 'EVERYDAY') {
               param.startDate = dateFactory.dateFormat(false)
               param.endDate = '9999-12-31'
               this.checkWeek.forEach(item => param[item] = 'YES')
            }
            if (this.dateType === 'WEEK'){
               param.startDate = dateFactory.dateFormat(false)
               param.endDate = '9999-12-31'
               this.checkWeek.forEach(item => param[item] = 'YES')
            }
            if (this.dateType === 'DATE') {
               param.startDate = this.dateRange[0]
               param.endDate = this.dateRange[1]
               this.checkWeek.forEach(item => param[item] = 'YES')
            }
            if (this.action === 'edit') {
               url = operate.editSeckillProduct
               param.id = this.id
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.edit_success,
                     type: 'success'
                  })
                  this.goBack()
               }
            })
         },
         // 返回上一页
         goBack(){
            this.$router.go(-1)
         },
      },
   }
</script>

<style scoped lang="scss">
.cont{
   min-width: 1120px;
   .seckill-form{
      background: #FFFFFF; padding: 65px 94px;
      > .el-row{ margin-bottom: 20px }
      .seckill-radio{
         .left-label{ float: left; text-align: right }
         .right-radio{
            float: left;
            .radio-flex{
               display: flex; align-items: center;
               .el-radio{ line-height: 35px; }
            }
         }
      }
   }
   .search-box{
      display: flex; padding: 10px 0 20px 0;
      .el-row{ margin: 0 10px 0 0 }
   }
}
.el-row{
   line-height: 30px;
   white-space: nowrap;
}
</style>
